import { Component, OnInit } from '@angular/core';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg'
import { ApiService } from '../api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactData: {
		firstname: string;
    lastname: string;
    company: string;
    phone: string;
    email: string;
    message: string;
  };

  contactBusy: boolean;
  
  constructor(private apiService: ApiService, private toaster: NgxNotificationMsgService) {
    this.contactData = {
      firstname: '',
      lastname: '',
      company: '',
      phone: '',
      email: '',
      message: ''
    };

    this.contactBusy = false;
  }

  ngOnInit(): void {
  }

  sendEmail(data: any): void {
    this.contactBusy = true;
    this.apiService.sendContactForm(data).subscribe((res: any)=>{
      this.contactBusy = false;
      if (res.success) {
        this.toaster.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: 'Contact',
          messages: ['Contact form has been submitted successfully.']
        })
        this.contactData = {
          firstname: '',
          lastname: '',
          company: '',
          phone: '',
          email: '',
          message: ''
        };
      } else {
        this.toaster.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: 'Contact',
          messages: ['Error while trying to submit a contact form.']
        })
      }
    }, (err) => {
      this.contactBusy = false;
      this.toaster.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: 'Contact',
        messages: ['Error while trying to submit a contact form.']
      })
    });
  }

}
