<section id="contact">
	<div class="container special">
		<h2><span class="textStrong">Contact</span> us</h2>
		<p>Our partners benefit from the vast amount of experience of the LoveTech Ventures professional team in accompanying and advising startups throughout their growth. 
		<div class="divider"></div>
		<form>
			<div class="formGroup">
				<div class="inputGroup">
					<div class="inputFrame">
						<input class="formControl" type="text" required name="firstname" [(ngModel)]="contactData.firstname"/>
						<span class="label">First name</span>
					</div>
					<div class="inputFrame">
						<input class="formControl" type="text" required name="lastname" [(ngModel)]="contactData.lastname"/>
						<span class="label">Last name</span>
					</div>
				</div>
				<div class="inputFrame">
					<input class="formControl" type="text" required name="company" [(ngModel)]="contactData.company"/>
					<span class="label">Company name</span>
				</div>
				<div class="inputFrame">
					<input class="formControl" type="text" required name="phone" [(ngModel)]="contactData.phone"/>
					<span class="label">Phone</span>
				</div>
				<div class="inputFrame">
					<input class="formControl" type="text" required name="email" [(ngModel)]="contactData.email"/>
					<span class="label">Email</span>
				</div>
			</div>
			<div class="formGroup">
				<div class="inputFrame textarea">
					<textarea required name="message" [(ngModel)]="contactData.message"></textarea>
					<span class="label">Type your message here...</span>
				</div>
			</div>
		</form>
		<button class="btn" (click)="sendEmail(contactData)" [ngClass]="{'loading': contactBusy}">
			<div *ngIf="contactBusy" class="loader"><div></div><div></div><div></div><div></div></div>
			Send
		</button>
	</div>
</section>