import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private REST_API_SERVER = "https://lovetechventures.com";

  constructor(private httpClient: HttpClient) { }

  public sendContactForm(contactData: any){
    return this.httpClient.post(this.REST_API_SERVER + '/api/v1/contact', contactData);
  }

  public sendApplicationForm(applicationData: any){
    return this.httpClient.post(this.REST_API_SERVER + '/api/v1/apply', applicationData);
  }
}
