<section id="investors">
    <div class="container special">
        <div class="investorsHeader">
            <h2><span class="textStrong">Investors</span>&<span class="textStrong">Partners</span></h2>
        </div>
        <div class="investorsDescriptionMobile">
            <div class="investorsDescription">
                <article class="think">
                    <div class="image">
                        <div class="circle"></div>
                        <div class="text">
                            <h3>We think differently!</h3>
                            <p>We think differently, and ignore taboo and stereotypes.</p>
                        </div>
                        <img src="../../assets/puzzle_red.svg" alt="Red puzzle"/>
                    </div>
                    <div class="text">
                        <p>"A year after being banned, Lora DiCarlo returns to CES with new sex toys"</p>
                        <br/>
                        - <a href="https://www.theverge.com/2020/1/5/21047986/lora-dicarlo-sex-toys-tech-banned-return-new-ces-2020" target="__blank">Read more...</a>
                    </div>
                </article>
                <article class="growth">
                    <div class="text">
                        <p>"Most popular online dating apps in the United States as of September 2019, by audience size"</p>
                        <br/>
                        - <a href="https://www.statista.com/statistics/826778/most-popular-dating-apps-by-audience-size-usa/" target="__blank">Read more...</a>
                    </div>
                    <div class="image">
                        <div class="circle"></div>
                        <div class="text">
                            <h3>Industry grows rapidly!</h3>
                            <p>SexTech industry is changing and growing rapidly.</p>
                        </div>
                        <img src="../../assets/puzzle_yellow.svg" alt="Yellow puzzle"/>
                    </div>
                </article>
                <article class="market">
                    <div class="image">
                        <div class="circle"></div>
                        <div class="text">
                            <h3>Huge market!</h3>
                            <p>As according to market research firm, statistics estimates the global sexual wellness market, including sex tech, will grow to $122.96 billion by 2026.</p>
                        </div>
                        <img src="../../assets/puzzle_blue.svg" alt="Yellow puzzle"/>
                    </div>
                    <div class="text">
                        <p>"Bumble swipes left on Tinder, declaring itself good David – let’s make that “Diana” vs. evil Goliath"</p>
                        <br/>
                        - <a href="https://www.liveworld.com/bumble-swipes-left-on-tinder/" target="__blank">Read more...</a>
                    </div>
                </article>
                <div class="divider large"></div>
                <article class="final">
                    <div class="puzzlesGroup" aTilt>
                        <img class="puzzle yellow" src="../../assets/puzzle_yellow_group.svg" alt="Puzzle yellow together"/>
                        <img class="puzzle red" src="../../assets/puzzle_red_group.svg" alt="Puzzle red together"/>
                        <img class="puzzle blue" src="../../assets/puzzle_blue_group.svg" alt="Puzzle blue together"/>
                        <img class="puzzle you" src="../../assets/puzzle_my_group.svg" alt="Puzzle you together"/>
                    </div>
                    <div class="text">
                        <h2>We put puzzle pieces together!</h2>
                        <p>LoveTech Ventures is a gateway to revolutionary an adult entertainment technologies in the Startup Nation and Global arena.</p>
                    </div>
                </article>
            </div>
        </div>
        <div class="investorsDescriptionNormal">
            <div class="investorsDescription">
                <article class="final">
                    <div class="puzzlesGroup" aTilt>
                        <div class="puzzle yellow">
                            <div class="text">
                                <h3>We think differently!</h3>
                                <p>We think differently, and ignore taboo and stereotypes.</p>
                            </div>
                            <img src="../../assets/puzzle_yellow_group.svg" alt="Puzzle yellow together"/>
                        </div>
                        <div class="puzzle red">
                            <div class="text">
                                <h3>Industry grows rapidly!</h3>
                                <p>SexTech industry is changing and growing rapidly.</p>
                            </div>
                            <img src="../../assets/puzzle_red_group.svg" alt="Puzzle red together"/>
                        </div>
                        <div class="puzzle blue">
                            <div class="text">
                                <h3>Huge market!</h3>
                                <p>As according to market research firm, statistics estimates the global sexual wellness market, including sex tech, will grow to $122.96 billion by 2026.</p>
                            </div>
                            <img src="../../assets/puzzle_blue_group.svg" alt="Puzzle blue together"/>
                        </div>
                        <div class="puzzle you">
                            <div class="text">
                                <h2>This is YOU!</h2>
                                <p>Propose a startup, we will help you grow.</p>
                            </div>
                            <img src="../../assets/puzzle_my_group.svg" alt="Puzzle you together"/>
                        </div>
                    </div>
                    <div class="text">
                        <h2>We put puzzle pieces together!</h2>
                        <p>LoveTech Ventures is a gateway to revolutionary an adult entertainment technologies in the Startup Nation and Global arena.</p>
                    </div>
                </article>
            </div>
        </div>
        <div class="divider large"></div>
        <div class="investorsDetails">
            <h3 class="title"><b>LoveTech Ventures is a gateway to revolutionary adult entertainment technologies in the Startup Nation and Global arena.</b></h3>
            <p>LoveTech Ventures innovation platform includes:</p>
            <ul>
                <li><p><b>Scouting</b> - Our professional team scouts and identifies the most promising and advanced technolog</p></li>
                <li><p><b>Evaluation</b> - Our partners enjoy a precise Due diligence and evaluation process from our professional team that includes business experts, accountants and financial advisors.</p></li>
                <li><p><b>Selection</b> - The process ensures that only the best and most relevant Startups will be introduced to our global partners</p></li>
            </ul>
            <p>Our partners benefit from the vast amount of experience of the LoveTech Ventures professional team in accompanying and advising startups throughout their growth.</p>
            <p>LoveTech Ventures supports its partners to stay at the forefront of adult entertainment technologies innovation.</p>
        </div>
    </div>
</section>