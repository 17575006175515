<section id="about">
    <div class="container special">
        <div class="aboutHeader">
            <div class="aboutText">
                <h2><span class="textStrong">About</span> us</h2>
                <p><strong>LoveTech Ventures</strong> is a world class innovation<br/>platform related to SexTech industry<br/>and adult entertainment technologies.</p>
                <div class="divider"></div>
                <div class="aboutDescription">
                    <article>
                        <h3>We expand our unique offerings</h3>
                        <p>LoveTech Ventures is continuing to expand its unique offerings and services, all with unprecedented successes and value added for startups, investors, partners, and consumers.</p>
                    </article>
                    <article>
                        <h3>We acelerate relevant startups</h3>
                        <p>LoveTech Ventures accelerates relevant startups with the ultimate goal of increasing viability, seeking additional investment and connecting to global partners immersed in the sex wellness (sexness) market and SexTech industry.</p>
                    </article>
                </div>
            </div>
            <div class="aboutImage">
                <div class="image">
                    <img class="animate__animated animate__fadeIn" src="../../assets/phone.webp" alt="Love tech Phone" aTilt />
                    <div class="imageFrame"></div>
                </div>
            </div>
        </div>
        <div class="divider large"></div>
        <div class="aboutDescriptionCards">
            <article>
                <div class="header">
                    <img class="icon" src="../../assets/team.svg" alt="Team icon" />
                    <h3>Great Team</h3>
                </div>
                <p>Our team provides startups with know-how, <strong>dedicated professional team of experts, business advisors, global partners</strong>, and a gateway to a $30 billion sex wellness industry market worldwide.</p>
            </article>
            <article>
                <div class="header">
                    <img class="icon" src="../../assets/vision.svg" alt="Vision icon" />
                    <h3>Super Vision</h3>
                </div>
                <p>Impacting and improving people’s sexness (sex wellness) and relationships (pleasure & entertainment) through technology innovation and abilities.</p>
            </article>
            <article>
                <div class="header">
                    <img class="icon" src="../../assets/mission.svg" alt="Mission icon" />
                    <h3>One Mission</h3>
                </div>
                <p>Empowering and enabling Startup companies globally to benefit SexTech industry and adult entertainment ecosystem.</p>
            </article>
        </div>
    </div>
</section>