import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  doesShowApplicationModal: boolean;
  
  constructor() {
    this.doesShowApplicationModal = false;
  }

  ngOnInit(): void {
  }

  showApplicationModal(show: boolean): void {
    this.doesShowApplicationModal = show;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
