<section id="startups">
    <div class="container special">
        <div class="startupsHeader">
            <h2><span class="textStrong">Start</span>ups</h2>
            <p>We support many SexTech startups with many different ideas.</p>
        </div>
        <div class="divider"></div>
        <div class="startupsHeader">
            <h2>Supported startups</h2>
        </div>
        <div class="startupsContent">
            <article class="biopharma">
                <div class="description">
                    <h3>Biopharma</h3>
                    <ul>
                        <li [ngClass]="{'focus': activeImage === 'biopharma_pills'}" (mouseover)="handleImageMouseOver('biopharma_pills')"
                        (mouseout)="handleImageMouseOver('')"><p>Unisex stimulation solutions</p></li>
                        <li [ngClass]="{'focus': activeImage === 'biopharma_gel'}" (mouseover)="handleImageMouseOver('biopharma_gel')"
                        (mouseout)="handleImageMouseOver('')"><p>Innovative lubricant solutions</p></li>
                        <li [ngClass]="{'focus': activeImage === 'biopharma_spray'}" (mouseover)="handleImageMouseOver('biopharma_spray')"
                        (mouseout)="handleImageMouseOver('')"><p>Infection protection solutions</p></li>
                        <li [ngClass]="{'focus': activeImage === 'biopharma_supplements'}" (mouseover)="handleImageMouseOver('biopharma_supplements')"
                        (mouseout)="handleImageMouseOver('')"><p>Food supplements</p></li>
                        <li [ngClass]="{'focus': activeImage === 'biopharma_virus'}" (mouseover)="handleImageMouseOver('biopharma_virus')"
                        (mouseout)="handleImageMouseOver('')"><p>Pharmaceutical developments</p></li>
                    </ul>
                </div>
                <div class="gallery">
                    <div 
                        class="image image1"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'biopharma_pills'}"
                        (mouseover)="handleImageMouseOver('biopharma_pills')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image2"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'biopharma_supplements'}"
                        (mouseover)="handleImageMouseOver('biopharma_supplements')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div 
                        class="image image3"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'biopharma_virus'}"
                        (mouseover)="handleImageMouseOver('biopharma_virus')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    ></div>
                    <div
                        class="image image4"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'biopharma_gel'}"
                        (mouseover)="handleImageMouseOver('biopharma_gel')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image5"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'biopharma_spray'}"
                        (mouseover)="handleImageMouseOver('biopharma_spray')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                </div>
            </article>
            <article class="devices">
                <div class="gallery">
                    <div 
                        class="image image1"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'devices_wearable'}"
                        (mouseover)="handleImageMouseOver('devices_wearable')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image2"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'devices_data'}"
                        (mouseover)="handleImageMouseOver('devices_data')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div 
                        class="image image3"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'devices_prediction'}"
                        (mouseover)="handleImageMouseOver('devices_prediction')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    ></div>
                    <div
                        class="image image4"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'devices_connected'}"
                        (mouseover)="handleImageMouseOver('devices_connected')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                </div>
                <div class="description">
                    <h3>Devices</h3>
                    <ul>
                        <li [ngClass]="{'focus': activeImage === 'devices_wearable'}" (mouseover)="handleImageMouseOver('devices_wearable')"
                        (mouseout)="handleImageMouseOver('')"><p>Wearable devices</p></li>
                        <li [ngClass]="{'focus': activeImage === 'devices_connected'}" (mouseover)="handleImageMouseOver('devices_connected')"
                        (mouseout)="handleImageMouseOver('')"><p>Connected devices</p></li>
                        <li [ngClass]="{'focus': activeImage === 'devices_prediction'}" (mouseover)="handleImageMouseOver('devices_prediction')"
                        (mouseout)="handleImageMouseOver('')"><p>Analytics and prediction devices</p></li>
                        <li [ngClass]="{'focus': activeImage === 'devices_data'}"(mouseover)="handleImageMouseOver('devices_data')"
                        (mouseout)="handleImageMouseOver('')"><p>Data driven solutions</p></li>
                    </ul>
                </div>
            </article>
            <article class="technology">
                <div class="description">
                    <h3>Technology</h3>
                    <ul>
                        <li [ngClass]="{'focus': activeImage === 'technology_sensors'}" (mouseover)="handleImageMouseOver('technology_sensors')"
                        (mouseout)="handleImageMouseOver('')"><p>Advance Sensors</p></li>
                        <li [ngClass]="{'focus': activeImage === 'technology_AI'}" (mouseover)="handleImageMouseOver('technology_AI')"
                        (mouseout)="handleImageMouseOver('')"><p>AI</p></li>
                        <li [ngClass]="{'focus': activeImage === 'technology_AR'}" (mouseover)="handleImageMouseOver('technology_AR')"
                        (mouseout)="handleImageMouseOver('')"><p>AR</p></li>
                        <li [ngClass]="{'focus': activeImage === 'technology_VR'}" (mouseover)="handleImageMouseOver('technology_VR')"
                        (mouseout)="handleImageMouseOver('')"><p>VR</p></li>
                        <li [ngClass]="{'focus': activeImage === 'technology_3D'}" (mouseover)="handleImageMouseOver('technology_3D')"
                        (mouseout)="handleImageMouseOver('')"><p>3D</p></li>
                    </ul>
                </div>
                <div class="gallery">
                    <div 
                        class="image image1"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'technology_AI'}"
                        (mouseover)="handleImageMouseOver('technology_AI')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image2"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'technology_sensors'}"
                        (mouseover)="handleImageMouseOver('technology_sensors')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div 
                        class="image image3"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'technology_AR'}"
                        (mouseover)="handleImageMouseOver('technology_AR')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    ></div>
                    <div
                        class="image image4"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'technology_3D'}"
                        (mouseover)="handleImageMouseOver('technology_3D')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image5"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'technology_VR'}"
                        (mouseover)="handleImageMouseOver('technology_VR')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                </div>
            </article>
            <article class="interaction">
                <div class="gallery">
                    <div 
                        class="image image1"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'interaction_communication'}"
                        (mouseover)="handleImageMouseOver('interaction_communication')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image2"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'interaction_games'}"
                        (mouseover)="handleImageMouseOver('interaction_games')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div 
                        class="image image3"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'interaction_education'}"
                        (mouseover)="handleImageMouseOver('interaction_education')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    ></div>
                    <div
                        class="image image4"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'interaction_mobile'}"
                        (mouseover)="handleImageMouseOver('interaction_mobile')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image5"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'interaction_social'}"
                        (mouseover)="handleImageMouseOver('interaction_social')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                </div>
                <div class="description">
                    <h3>Interaction</h3>
                    <ul>
                        <li [ngClass]="{'focus': activeImage === 'interaction_social'}" (mouseover)="handleImageMouseOver('interaction_social')"
                        (mouseout)="handleImageMouseOver('')"><p>Social networks</p></li>
                        <li [ngClass]="{'focus': activeImage === 'interaction_education'}" (mouseover)="handleImageMouseOver('interaction_education')"
                        (mouseout)="handleImageMouseOver('')"><p>Education</p></li>
                        <li [ngClass]="{'focus': activeImage === 'interaction_communication'}" (mouseover)="handleImageMouseOver('interaction_communication')"
                        (mouseout)="handleImageMouseOver('')"><p>Communication</p></li>
                        <li [ngClass]="{'focus': activeImage === 'interaction_mobile'}" (mouseover)="handleImageMouseOver('interaction_mobile')"
                        (mouseout)="handleImageMouseOver('')"><p>Mobile</p></li>
                        <li [ngClass]="{'focus': activeImage === 'interaction_games'}" (mouseover)="handleImageMouseOver('interaction_games')"
                        (mouseout)="handleImageMouseOver('')"><p>Games</p></li>
                    </ul>
                </div>
            </article>
            <article class="content">
                <div class="description">
                    <h3>Content</h3>
                    <ul>
                        <li [ngClass]="{'focus': activeImage === 'content_blogs'}" (mouseover)="handleImageMouseOver('content_blogs')"
                        (mouseout)="handleImageMouseOver('')"><p>Blogs/Vlogs</p></li>
                        <li [ngClass]="{'focus': activeImage === 'content_video'}" (mouseover)="handleImageMouseOver('content_video')"
                        (mouseout)="handleImageMouseOver('')"><p>Video</p></li>
                        <li [ngClass]="{'focus': activeImage === 'content_anime'}" (mouseover)="handleImageMouseOver('content_anime')"
                        (mouseout)="handleImageMouseOver('')"><p>Anime</p></li>
                        <li [ngClass]="{'focus': activeImage === 'content_3D'}" (mouseover)="handleImageMouseOver('content_3D')"
                        (mouseout)="handleImageMouseOver('')"><p>3D</p></li>
                        <li [ngClass]="{'focus': activeImage === 'content_audio'}" (mouseover)="handleImageMouseOver('content_audio')"
                        (mouseout)="handleImageMouseOver('')"><p>Audio</p></li>
                        <li [ngClass]="{'focus': activeImage === 'content_adult'}" (mouseover)="handleImageMouseOver('content_adult')"
                        (mouseout)="handleImageMouseOver('')"><p>Adult</p></li>
                    </ul>
                </div>
                <div class="gallery">
                    <div 
                        class="image image1"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'content_blogs'}"
                        (mouseover)="handleImageMouseOver('content_blogs')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image2"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'content_3D'}"
                        (mouseover)="handleImageMouseOver('content_3D')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div 
                        class="image image3"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'content_video'}"
                        (mouseover)="handleImageMouseOver('content_video')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    ></div>
                    <div
                        class="image image4"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'content_anime'}"
                        (mouseover)="handleImageMouseOver('content_anime')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image5"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'content_audio'}"
                        (mouseover)="handleImageMouseOver('content_audio')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                    <div
                        class="image image6"
                        [ngClass]="{'unfocus': activeImage !== '' && activeImage !== 'content_adult'}"
                        (mouseover)="handleImageMouseOver('content_adult')"
                        (mouseout)="handleImageMouseOver('')"
                        aTilt
                    >
                    </div>
                </div>
            </article>
        </div>
        <div class="divider large"></div>
        <div class="startupsTabs">
            <div class="tabs">
                <button class="btn btn-transparent" [ngClass]="{'active' : activeTab === 1}" (click)="setActiveTab(1)"><img class="icon" src="../../assets/speedometer.svg" alt="Speedometer"/>Accelerator</button>
                <button class="btn btn-transparent" [ngClass]="{'active' : activeTab === 2}" (click)="setActiveTab(2)"><img class="icon" src="../../assets/strategy.svg" alt="Strategy"/>Program</button>
                <button class="btn btn-transparent" [ngClass]="{'active' : activeTab === 3}" (click)="setActiveTab(3)"><img class="icon" src="../../assets/united.svg" alt="United"/>Collaboration</button>
                <button class="btn btn-transparent" [ngClass]="{'active' : activeTab === 4}" (click)="setActiveTab(4)"><img class="icon" src="../../assets/instructor.svg" alt="Instructor"/>Mentors and Experts</button>
            </div>
            <div class="content">
                <p *ngIf="activeTab === 1">LoveTech Ventures accelerates and invests in startups from pre-seed to initial revenues.</p>
                <p *ngIf="activeTab === 2">Our team provides a tailor-made program to best match the startups stage and support for specific needs.</p>
                <p *ngIf="activeTab === 3">We support creating Corporate “Traction” to develop POC’s (Proof of concept) and pilots with targeted partners.</p>
                <p *ngIf="activeTab === 4">Startups enjoy mentors and experts in the relevant industry, access to investors and global partners, a dedicated team of professionals and more.</p>
            </div>
        </div>
        <div class="divider large"></div>
        <app-modal id="applicationModal" [show]="doesShowApplicationModal" (getShowChange)="showApplicationModal($event)">
            <h2><span class="textStrong">Tell us about your startup</span></h2>
            <form>
                <div class="formGroup">
                    <div class="inputGroup">
                        <div class="inputFrame">
                            <input class="formControl" type="text" required name="firstname" [(ngModel)]="applicationData.firstname"/>
                            <span class="label">First name</span>
                        </div>
                        <div class="inputFrame">
                            <input class="formControl" type="text" required name="lastname" [(ngModel)]="applicationData.lastname"/>
                            <span class="label">Last name</span>
                        </div>
                    </div>
                    <div class="inputFrame">
                        <input class="formControl" type="text" required name="company" [(ngModel)]="applicationData.company"/>
                        <span class="label">Company name</span>
                    </div>
                    <div class="inputFrame">
                        <input class="formControl" type="text" required name="website" [(ngModel)]="applicationData.website"/>
                        <span class="label">Company website</span>
                    </div>
                    <div class="inputFrame">
                        <input class="formControl" type="text" required name="phone" [(ngModel)]="applicationData.phone"/>
                        <span class="label">Phone</span>
                    </div>
                    <div class="inputFrame">
                        <input class="formControl" type="text" required name="email" [(ngModel)]="applicationData.email"/>
                        <span class="label">Email</span>
                    </div>
                </div>
                <div class="formGroup">
                    <div class="inputFrame textarea">
                        <textarea required name="message" [(ngModel)]="applicationData.message"></textarea>
                        <span class="label">Pitch your idea...</span>
                    </div>
                    <div class="inputFrame" (click)="investorDesk.click()">
                        <div class="fileInput">
                            <span class="icon-attachment"></span>
                            <span>Attach most recent Investor Deck</span>
                        </div>
                        <input #investorDesk class="formControl" type="file" required style="visibility: hidden" name="attachments" [(ngModel)]="applicationData.attachments" observeFiles/>
                    </div>
                </div>
            </form>
            <button class="btn" (click)="sendApplication(applicationData)" [ngClass]="{'loading': applyBusy}">
                <div *ngIf="applyBusy" class="loader"><div></div><div></div><div></div><div></div></div>
                Apply
            </button>
        </app-modal>
    </div>
    <div id="application" class="startupsApplication">
        <div class="container special">
            <div class="applicationImage">
                <img src="../../assets/tablet.webp" alt="Tablet" aTilt/>
            </div>
            <div class="applicationDescription">
                <h2><span class="textStrong white">Apply Now!</span></h2>
                <p>We look for relevant SexTech startups and adult entertainment technologies with</p>
                <ul>
                    <li><p>Dedicated team</p></li>
                    <li><p>Innovation or category creator</p></li>
                    <li><p>Scalable application</p></li>
                    <li><p>Market and industry match</p></li>
                </ul>
                <button class="btn btn-secondary large" (click)="showApplicationModal(true)">Pitch Idea</button>
            </div>
        </div>
    </div>
</section>