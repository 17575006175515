import { Component, OnInit } from '@angular/core';
import { NgxNotificationMsgService, NgxNotificationStatusMsg } from 'ngx-notification-msg';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-startups',
  templateUrl: './startups.component.html',
  styleUrls: ['./startups.component.css']
})
export class StartupsComponent implements OnInit {
  activeTab: number;
  doesShowApplicationModal: boolean;
  activeImage: string;
  applyBusy: boolean;

  applicationData: {
		firstname: string;
    lastname: string;
    company: string;
    website: string;
    phone: string;
    email: string;
    message: string;
		attachments: File[] | null;
  };
  
  constructor(private apiService: ApiService, private toaster: NgxNotificationMsgService) {
    this.activeTab = 1;
    this.doesShowApplicationModal = false;
    this.activeImage = '';
    this.applicationData = {
      firstname: '',
      lastname: '',
      company: '',
      website: '',
      phone: '',
      email: '',
      message: '',
      attachments: null
    };

    this.applyBusy = false;
  }

  ngOnInit(): void {
  }

  setActiveTab(tab: number): void {
    this.activeTab = tab;
  }

  showApplicationModal(show: boolean): void {
    this.doesShowApplicationModal = show;
  }
  
  handleImageMouseOver(element: string): void {
    this.activeImage = element;
  }

  sendApplication(data: any): void {
    this.applyBusy = true;
    this.apiService.sendApplicationForm(data).subscribe((res: any)=>{
      this.applyBusy = false;
      if (res.success) {
        this.toaster.open({
          status: NgxNotificationStatusMsg.SUCCESS,
          header: 'Application',
          messages: ['Startup application has been submitted successfully.']
        })
        this.applicationData = {
          firstname: '',
          lastname: '',
          company: '',
          website: '',
          phone: '',
          email: '',
          message: '',
          attachments: null
        };
        this.showApplicationModal(false);
      } else {
        this.toaster.open({
          status: NgxNotificationStatusMsg.FAILURE,
          header: 'Application',
          messages: ['Error while trying to submit the application form.']
        })
      }
    }, (err) => {
      this.applyBusy = false;
      this.toaster.open({
        status: NgxNotificationStatusMsg.FAILURE,
        header: 'Application',
        messages: ['Error while trying to submit the application form.']
      })
    });
  }
}
