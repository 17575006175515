<div class="mobileMenu" [ngClass]="{'show': show}">
    <div class="mobileMenuBody">
        <button class="btn btn-rounded btn-secondary menuClose" (click)="close()">
            <span class="icon-close"></span>
        </button>
        <nav>
            <a href="#about" pageScroll [pageScrollDuration]="300" (click)="close()"><div class="tracker"></div>About</a>
            <a href="#startups" pageScroll [pageScrollDuration]="350" (click)="close()"><div class="tracker"></div>Startups</a>
            <a href="#investors" pageScroll [pageScrollDuration]="450" (click)="close()"><div class="tracker"></div>Investors & Corporates</a>
            <a href="#contact" pageScroll [pageScrollDuration]="500" (click)="close()"><div class="tracker"></div>Contact</a>
            <a href="#application" pageScroll [pageScrollDuration]="400" class="btn" (click)="close()">Apply</a>
        </nav>
    </div>
</div>
<div class="mobileMenuBackground" [ngClass]="{'show': show}"></div>