import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input()
  show: boolean;
  @Output() getShowChange = new EventEmitter<boolean>();

  constructor() { }
  ngOnInit(): void {}
  
  close(): void {
    this.show = false;
    this.getShowChange.emit(false);
  }

}
