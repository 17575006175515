import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { StartupsComponent } from './startups/startups.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ModalComponent } from './modal/modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { FileInputDirective } from './file-input.directive';
import { InvestorsComponent } from './investors/investors.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';

import { NgxNotificationMsgModule } from 'ngx-notification-msg';
import { AngularTiltModule } from 'angular-tilt';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AboutComponent,
    StartupsComponent,
    ModalComponent,
    FileInputDirective,
    InvestorsComponent,
    ContactComponent,
    FooterComponent,
    MobileMenuComponent
  ],
  imports: [
    BrowserModule,
    NgxPageScrollModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxNotificationMsgModule,
    AngularTiltModule,
  ],
  providers: [
    Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
