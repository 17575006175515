<div id="top"></div>
<header id="header">
    <div class="container">
        <div class="headerMenu">
            <img class="logo animate__animated animate__heartBeat" src="../../assets/logo.svg" alt="LoveTech Venture Logo" />
            <nav>
                <a href="#about" pageScroll [pageScrollDuration]="300"><div class="tracker"></div>About</a>
                <a href="#startups" pageScroll [pageScrollDuration]="350"><div class="tracker"></div>Startups</a>
                <a href="#investors" pageScroll [pageScrollDuration]="450"><div class="tracker"></div>Investors & Partners</a>
                <a href="#contact" pageScroll [pageScrollDuration]="500"><div class="tracker"></div>Contact</a>
            </nav>
        </div>
        <div class="headerData">
            <div class="headerDescription">
                <h3 class="animate__animated animate__fadeInLeft companyName">LoveTech Ventures</h3>
                <h2 class="animate__animated animate__fadeInLeft animated__delay-1s">
                    Innovation platform<br/>
                    focusing on<br/>
                    <span class="textStrong">SexTech industry</span> &<br/>
                    <span class="textStrong">sex wellness market</span>.
                </h2>
                <h3 class="animate__animated animate__fadeInLeft animated__delay-1s">LoveTech is not only SexTech,<br/>it is a much more...</h3>
                <a href="#application" pageScroll [pageScrollDuration]="300" class="btn animate__animated animate__fadeInLeft animated__delay-1s">Run a Startup?</a>
            </div>
            <div class="headerInfo">
                <div class="headerImage" >
                    <img class="animate__animated animate__fadeIn" src="../../assets/header.webp" alt="People in love" aTilt />
                    <div class="imageFrame" aTilt></div>
                    <div class="circle" aTilt></div>
                    <img class="pattern" src="../../assets/pattern.svg" alt="Pattern" />
                </div>
            </div>
        </div>
        <button class="btn btn-rounded" id="showMobileMenuButton" (click)="showApplicationModal(true)"><span class="icon-menu"></span></button>
    </div>
</header>
<div id="backToTop">
    <button class="btn btn-rounded large btn-secondary" href="#top" pageScroll [pageScrollDuration]="300"><span class="icon-arrowup"></span></button>
</div>
<app-mobile-menu [show]="doesShowApplicationModal" (getShowChange)="showApplicationModal($event)"></app-mobile-menu>
